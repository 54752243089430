import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "shipyard.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <div className="hero">
      <BackgroundImage
        style={{
          minHeight: '80vh',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          padding: '2rem',
        }}
        // fluid={data.file.childImageSharp.fluid}
        fluid={[
          'linear-gradient(to right, #3b1b8d, rgba(4, 243, 67, 0.0))',
          data.file.childImageSharp.fluid,
        ]}
      >
        <h1>The right personnel is the key to your company's success</h1>
      </BackgroundImage>
      <Img fluid={data.file.childImageSharp.fluid} />
    </div>
  );
};

export default Hero;
